<template>
  <div id="treemapoptions">
    <el-form-item label="Distributed Colors">
      <el-switch
        @change="updateChart"
        inactive-color="#24272b"
        v-model="plotOptions.treemap.distributed"
      ></el-switch>
    </el-form-item>

    <div>
      <h4>Color Shade Intensity</h4>
      <div class="block">
        <el-slider
          @change="updateChart"
          v-model="plotOptions.treemap.shadeIntensity"
          :max="0.8"
          :min="0"
          :step="0.05"
          show-input
        ></el-slider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plotOptions: {
        treemap: {
          distributed: false,
          shadeIntensity: 0.5,
        },
      },
    };
  },
  watch: {
    chartConfig: function(newProp) {
      const p = newProp.plotOptions;
      this.setNewOptions(p);
    },
  },
  mounted: function() {
    const p = this.getChartConfig().w.config.plotOptions;
    this.setNewOptions(p);
  },
  methods: {
    setNewOptions(p) {
      this.plotOptions = {
        treemap: {
          distributed: p.treemap.distributed,
          shadeIntensity: p.treemap.shadeIntensity,
        },
      };
    },
    updateChart: function() {
      const p = this.plotOptions;
      this.updateChartOptions({
        plotOptions: {
          treemap: {
            distributed: p.treemap.distributed,
            shadeIntensity: p.treemap.shadeIntensity,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
